import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import Button from '../../Button';
import { bannerHeaderVar } from '../../../utils/framerVariants';

const LandingHero = ( { headings } ) => {
	const [ index, setIndex ] = useState( 0 );

	useEffect( () => {
		const interval = setInterval( () => {
			setIndex( prev => prev < headings.length - 1 ? prev + 1 : 0 );
		}, 5000 );

		return () => {
			clearInterval( interval );
		};
	}, [ headings.length, setIndex ] );

	return (
		<section id="hero-26" className="bg--scroll hero-section">
			<div className="container">
				<div className="row d-flex align-items-center">

					<div className="col-md-6">
						<div className="hero-26-txt color--white">
							<img className="hero-logo" src="/media/progmentumlogodk.png" alt="hero-logo"/>
							<h2 className="s-50 w-700 flex-nowrap">Efficiency Amplified<br></br>Programs Simplified</h2>
							<p className="p-lg">{ headings[ index ].subHeading}
							</p>

							<form id="mc-form" className="quick-form form-shadow form-half mt-35">

								<div className="input-group">
									<input type="email" name="mc-email" id="mc-email" className="form-control email r-06" placeholder="Your email address" autoComplete="off" required/>
									<span className="input-group-btn form-btn">
										<button type="submit" className="btn r-04 btn--theme hover--blue-300 submit">Get Started</button>
									</span>
								</div>

								<div className="quick-form-msg"><span className="loading"></span></div>

							</form>

						</div>
					</div>

					<div className="col-md-6">
						<div className="hero-26-img wow fadeInLeft">
							<img className="img-fluid" src="/media/hero_progapp.png" alt="hero-image"/>
						</div>
					</div>

				</div>
			</div>

			<div className="wave-shape-bottom">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 290"><path fillOpacity="1" d="M0,256L120,250.7C240,245,480,235,720,224C960,213,1200,203,1320,197.3L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
			</div>

		</section>
	);
};

LandingHero.propTypes = { headings: PropTypes.array };

export default LandingHero;
